export const INTERVAL = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly'
} as const;

export const SALES_INTERVAL = {
  YEAR_2: 'year_2',
  YEAR_3: 'year_3'
} as const;

export const SUPPORTED_LANGUAGES = {
  DE_CH: 'de_CH',
  EN_US: 'en_US'
} as const;

export const CURRENCY = {
  CHF: 'CHF',
  EUR: 'EUR'
} as const;

export const RENDER_MODE = {
  DASHBOARD: 'dashboard',
  WEBSITE: 'website',
  SALES: 'sales',
} as const;

export const SUBSCRIPTION = {
  BASIC: 'basic',
  PROFESSIONAL: 'professional',
  PREMIUM: 'premium',
  BUSINESS: 'business',
  ENTERPRISELITE: 'enterpriselite',
  ENTERPRISE: 'enterprise'
} as const;

export const SUBSCRIPTION_IDS = {
  basic: 103,
  professional: 102,
  premium: 102,
  business: 104,
  enterprise: 105,
  enterpriselite: 106
} as const;

export const APP_CONSTANTS = {
  DEFAULT_LANG: SUPPORTED_LANGUAGES.DE_CH,
  CALENSO_SUPPORT_EMAIL: 'support@calenso.com'
};

export const LANGUAGES = [{
  text: 'german',
  value: 'de',
  secondary_text: 'DE',
  locale: 'de_CH',
  flagIcon: '/assets/images/flags/german.png',
}, {
  text: 'english',
  value: 'en',
  secondary_text: 'EN',
  locale: 'en_US',
  flagIcon: '/assets/images/flags/english.png',
}];

export const PRICING_PLAN_PRECEDENCE = {
  103: 0, // BASIC
  102: 1, // PROFESSIONAL || PREMIUM
  104: 2, // BUSINESS
  105: 3, // ENTERPRISE
  106: 4 // ENTERPRISELITE
}

export const CUSTOM_PLAN_USER_TYPE = 'additional_users';
export const CUSTOM_PLAN_STORE_TYPE = 'additional_stores';
export const CUSTOM_PLAN_GROUP_APPOINTMENT_TYPE = 'additional_events';
export const CUSTOM_PLAN_CONFERENCE_TYPE = 'additional_conferences';

export const DECIMAL_DIGITS = '1.2-2';
export const DECIMAL_LOCALE = 'en';
