import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MAX_LIMIT } from '@constants/pricing.constants';
import { PricingDetails } from '@db-models/pricing-db.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  private httpClient = inject(HttpClient);

  getCurrentFromIp(): Observable<any> {
    return this.httpClient.get('https://ipapi.co/json/', {});
  }

  getParamsFromUrl(urlObject: URL): any {
    const paramsObject: any = {};
    const searchParams = urlObject.searchParams;
    searchParams.forEach((value, key) => paramsObject[key] = value);
    return paramsObject;
  }

  setupMaxLimiteInPricingList(pricingList: PricingDetails[], showUserBasedPricing = false): PricingDetails[] {
    const temp = structuredClone(pricingList);
    temp.filter((plan, index) => {
      if (index < pricingList.length - 1) {
        let nextPlan = pricingList[index + 1];
        plan.max_workers = nextPlan.min_workers - (nextPlan.min_workers > plan.min_workers ? 1 : 0);
        plan.max_store = nextPlan.min_store - (nextPlan.min_store > plan.min_store ? 1 : 0);
        plan.max_group_appointment = nextPlan.min_group_appointment - (nextPlan.min_group_appointment > plan.min_group_appointment ? 1 : 0);
        plan.max_conference = nextPlan.min_conference - (nextPlan.min_conference > plan.min_conference ? 1 : 0);

        if (showUserBasedPricing) {
          plan?.chf?.monthly?.user_base_price && (plan.chf.monthly.original_price = plan.chf.monthly.user_base_price);
          plan?.chf?.yearly?.user_base_price && (plan.chf.yearly.original_price = plan.chf.yearly.user_base_price);
          plan?.eur?.monthly?.user_base_price && (plan.eur.monthly.original_price = plan.eur.monthly.user_base_price);
          plan?.eur?.yearly?.user_base_price && (plan.eur.yearly.original_price = plan.eur.yearly.user_base_price);
        }
      } else {
        // Set the max workers for the last plan to a default value or based on your requirement
        plan.max_workers = plan.max_store = plan.max_group_appointment = plan.max_conference = MAX_LIMIT; // Set a default value
      }
    });
    return temp;
  }
}
