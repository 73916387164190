import { PricingDetails } from "@db-models/pricing-db.model";

export const SALES_PRICING_JSON: PricingDetails[] = [
  {
    chf: {
      year_3: {
        original_price: 18.90,
      },
      year_2: {
        original_price: 20.90,
      },
      yearly: {
        original_price: 24.90,
      },
      monthly: {
        original_price: 29.90,
      }
    },
    eur: {
      year_3: {
        original_price: 18.90,
      },
      year_2: {
        original_price: 20.90,
      },
      yearly: {
        original_price: 24.90,
      },
      monthly: {
        original_price: 29.90,
      }
    },
    description: "Calenso basic",
    id: 103,
    included_bookings: 0,
    name: "basic",
    min_workers: 1,
    min_store: 1,
    min_group_appointment: 1,
    min_conference: 0,
    subscription_title: 'Basic',
    subscription_subtitle: {
      en_US: "The perfect starting point for GDPR-compalint online appointment scheduling.",
      de_CH: "Der perfekte Ausgangspunkt für die DSGVO-konforme Online-Terminplanung."
    },
    feature_list_title: {
      en_US: "Basis for scheduling your team",
      de_CH: "Basis für die Terminvereinbarung"
    },
    feature_list: {
      sales: [
        {
          en_US: "1 user",
          de_CH: "1 Benutzer"
        },
        {
          en_US: "1 store",
          de_CH: "1 Filiale"
        },
        {
          en_US: "GDPR & nDSG as standard",
          de_CH: "DSGVO & nDSG als Standard"
        },
        {
          en_US: "ISO 27001-certified servers",
          de_CH: "ISO 27001-zertifizierte Server"
        },
        {
          en_US: "<a href=\"https://www.myrasecurity.com/en/saasp/application-security/hyperscale-waf/\" target='_blank'>BSI-certified firewall</a>",
          de_CH: "<a href=\"https://www.myrasecurity.com/en/saasp/application-security/hyperscale-waf/\" target='_blank'>BSI-zertifizierte Firewall</a>"
        },
        {
          en_US: "Individual appointment scheduling",
          de_CH: "Einzelterminbuchung"
        },
        {
          en_US: "Round Robin",
          de_CH: "Round Robin"
        },
        {
          en_US: "Individual forms",
          de_CH: "Individuelle Formulare"
        },
        {
          en_US: "Website integration",
          de_CH: "Webseiten Integration"
        },
        {
          en_US: "Appointment reminders",
          de_CH: "Terminerinnerungen"
        },
        {
          en_US: "Self-service support",
          de_CH: "Self-Service-Support"
        },
        {
          en_US: "Google Calendar",
          de_CH: "Google Kalender"
        },
        {
          en_US: "Online Payment (Stripe, Coupons)",
          de_CH: "Online Payment (Stripe, Rabattverwaltung)"
        },
        {
          en_US: "No MS-Exchange, Office365, iCloud or CalDAV calandars",
          de_CH: "Keine MS-Exchange, Office365, iCloud oder CalDAV-Kalender",
          include: false
        },
        {
          en_US: "No PayPal, SaferPay, Bexio invoices",
          de_CH: "Kein PayPal, SaferPay, Bexio-Rechnungen",
          include: false
        },
        {
          en_US: "No GoTo-Meeting, Webex, Unblu",
          de_CH: "No GoTo-Meeting, Webex, Unblu",
          include: false
        },
      ]
    },
  },
  {
    chf: {
      year_3: {
        original_price: 68.90,
      },
      year_2: {
        original_price: 70.90,
      },
      yearly: {
        original_price: 74.90,
      },
      monthly: {
        original_price: 89.90,
      }
    },
    eur: {
      year_3: {
        original_price: 68.90,
      },
      year_2: {
        original_price: 70.90,
      },
      yearly: {
        original_price: 74.90,
      },
      monthly: {
        original_price: 89.90,
      }
    },
    description: "Calenso professional",
    id: 102,
    included_bookings: 0,
    name: "professional",
    min_workers: 3,
    min_store: 3,
    min_group_appointment: 10,
    min_conference: 0,
    subscription_title: 'Professional',
    subscription_subtitle: {
      en_US: "Ideal for businesses seeking personalized branding and dependable data protection.",
      de_CH: "Ideal für Unternehmen, die personalisierte Markenbildung und zuverlässigen Datenschutz suchen."
    },
    feature_list_title: {
      en_US: "Everything in Basic, plus",
      de_CH: "Alles in Basic, plus"
    },
    feature_list: {
      sales: [
        {
          en_US: "3 users",
          de_CH: "3 Benutzer"
        },
        {
          en_US: "3 stores",
          de_CH: "3 Filialen"
        },
        {
          en_US: "10 group appointments",
          de_CH: "10 Gruppentermine"
        },
        {
          en_US: "Custom logo",
          de_CH: "Benutzerdefiniertes Logo"
        },
        {
          en_US: "Video Meetings",
          de_CH: "Video Beratung"
        },
        {
          en_US: "Branches & team structure",
          de_CH: "Filialen & Team-Struktur"
        },
        {
          en_US: "Analyzes and statistics",
          de_CH: "Analysen und Statistiken"
        },
        {
          en_US: "Standard Support via email (72h)",
          de_CH: "Standard Support via E-Mail (72 Std.)"
        },
        {
          en_US: "Google Kalender, Office365, iCloud & CalDAV calendar",
          de_CH: "Google Kalender, Office365, iCloud & CalDAV-Kalender"
        },
        {
          en_US: "Online Payment (incl. Bexio invoices)",
          de_CH: "Online Payment (inkl. Bexio Rechnungen)"
        },
        {
          en_US: "No Microsoft Exchange calendar",
          de_CH: "Keinen Microsoft Exchange",
          include: false
        },
      ]
    },
  },
  {
    chf: {
      year_3: {
        original_price: 109.90,
      },
      year_2: {
        original_price: 149.90,
      },
      yearly: {
        original_price: 199.90,
      },
      monthly: {
        original_price: 249.90,
      }
    },
    eur: {
      year_3: {
        original_price: 109.90,
      },
      year_2: {
        original_price: 149.90,
      },
      yearly: {
        original_price: 199.90,
      },
      monthly: {
        original_price: 249.90,
      }
    },
    description: "Calenso Business",
    id: 104,
    included_bookings: 0,
    name: "business",
    min_workers: 5,
    min_store: 5,
    min_group_appointment: 15,
    min_conference: 5,
    subscription_title: 'Business',
    subscription_subtitle: {
      en_US: "Tailored for companies aiming to digitize their appointment processes with a focus on reliable data protection and unique branding.",
      de_CH: "Zugeschnitten für Unternehmen, die ihre Terminprozesse digitalisieren möchten, mit Schwerpunkt auf zuverlässigem Datenschutz und einzigartigem Branding."
    },
    feature_list_title: {
      en_US: "Everything in Professional, plus",
      de_CH: "Alles in Professional, plus"
    },
    feature_list: {
      sales: [
        {
          en_US: "5 users",
          de_CH: "5 Benutzer"
        },
        {
          en_US: "5 stores",
          de_CH: "5 Filialen"
        },
        {
          en_US: "5 conference appointments",
          de_CH: "5 Konferenztermine"
        },
        {
          en_US: "15 group appointments",
          de_CH: "15 Gruppentermine"
        },
        {
          en_US: "Multilingualism",
          de_CH: "Mehrsprachigkeit"
        },
        {
          en_US: "Individual design & branding",
          de_CH: "Individuelles Design & Branding"
        },
        {
          en_US: "Qualification questions (routing)",
          de_CH: "Qualifizierungsfragen (Routing)"
        },
        {
          en_US: "Smart-Actions (Workflows)",
          de_CH: "Smart-Actions (Workflows)"
        },
        {
          en_US: "Encrypted customer and appointment data",
          de_CH: "Verschlüsselte Kunden- und Termindaten"
        },
        {
          en_US: "Priority email support with callback service (24h)",
          de_CH: "Priorisierter Support via E-Mail mit Rückrufservice (innerhalb 24 Std.)"
        },
        {
          en_US: "Online Payment incl. SaferPay (Klarna, TWINT, PostFinance)",
          de_CH: "Online Payment inkl. SaferPay (Klarna, TWINT, PostFinance)"
        },
        {
          en_US: 'Mail sending from your own SMTP mail server',
          de_CH: 'Mailversand von eigenem SMTP-Mailserver'
        },
        {
          en_US: "No Microsoft Exchange Impersonation",
          de_CH: "Keine Microsoft Exchange Impersonation",
          include: false
        },
        {
          en_US: "No REST api access",
          de_CH: "Kein REST-API Zugriff",
          include: false
        },
        {
          en_US: "No audit logs",
          de_CH: "Keine Audit-Logs",
          include: false
        },
        {
          en_US: "No appointment status feature",
          de_CH: "Keine Termin-Status Funktion",
          include: false
        },
        {
          en_US: "No booking limit feature",
          de_CH: "Keine Buchungslimit Funktion",
          include: false
        },
        {
          en_US: "No postcode routing",
          de_CH: "Kein Postleitzahl-Routing",
          include: false
        }
      ]
    }
  },
  {
    chf: {
      year_3: {
        original_price: 299.90,
      },
      year_2: {
        original_price: 399.90,
      },
      yearly: {
        original_price: 499.90,
      },
      monthly: {
        original_price: 599.90,
      }
    },
    eur: {
      year_3: {
        original_price: 299.90,
      },
      year_2: {
        original_price: 399.90,
      },
      yearly: {
        original_price: 499.90,
      },
      monthly: {
        original_price: 599.90,
      }
    },
    description: "Calenso Enterprise Lite",
    id: 106,
    included_bookings: 0,
    name: "enterpriselite",
    min_workers: 10,
    min_store: 5,
    min_group_appointment: 20,
    min_conference: 10,
    subscription_title: 'Enterprise Lite',
    subscription_subtitle: {
      en_US: "Suited for companies requiring secure, scalable, and customizable software solutions for digitizing their appointment processes.",
      de_CH: "Geeignet für Unternehmen, die sichere, skalierbare und anpassbare Softwarelösungen für die Digitalisierung ihrer Terminprozesse benötigen."
    },
    feature_list_title: {
      en_US: "Everything in Business, plus",
      de_CH: "Alles in Business, plus"
    },
    feature_list: {
      sales: [
        {
          en_US: "10 users",
          de_CH: "10 Benutzer"
        },
        {
          en_US: "5 stores",
          de_CH: "5 Filialen"
        },
        {
          en_US: "10 conference appointments",
          de_CH: "10 Konferenztermine"
        },
        {
          en_US: "20 group appointments",
          de_CH: "20 Gruppentermine"
        },
        {
          en_US: "Audit Logs (5 days retention)",
          de_CH: "Audit Logs (5 Tage Aufbewahrungszeit)"
        },
        {
          en_US: "No Single-Sign-On (SSO): Microsoft Azure AD, Active Directory / LDAP",
          de_CH: "Kein Single-Sign-On (SSO): Microsoft Azure AD, Active Directory / LDAP",
          include: false
        },
        {
          en_US: "No custom roles",
          de_CH: "Keine firmenspezifische Benutzer-Rollen",
          include: false
        },
        {
          en_US: "No InxMail integration",
          de_CH: "Keine InxMail Integration",
          include: false
        },
        {
          en_US: "No dedicated test system",
          de_CH: "Kein Dediziertes Test-System",
          include: false
        },
        {
          en_US: "No personal account manager",
          de_CH: "Kein persönlicher Account-Manager",
          include: false
        }
      ]
    },
  },
  {
    chf: {
      year_3: {
        original_price: 1299.90,
      },
      year_2: {
        original_price: 1399.90,
      },
      yearly: {
        original_price: 1499.90,
      },
      monthly: {
        original_price: 1599.90,
      }
    },
    eur: {
      year_3: {
        original_price: 1299.90,
      },
      year_2: {
        original_price: 1399.90,
      },
      yearly: {
        original_price: 1499.90,
      },
      monthly: {
        original_price: 1599.90,
      }
    },
    description: "Calenso Enterprise",
    id: 105,
    included_bookings: 0,
    name: "enterprise",
    min_workers: 25,
    min_store: 10,
    min_group_appointment: 30,
    min_conference: 10,
    subscription_title: 'Enterprise',
    subscription_subtitle: {
      en_US: "Suited for companies requiring secure, scalable, and customizable software solutions for digitizing their appointment processes.",
      de_CH: "Geeignet für Unternehmen, die sichere, skalierbare und anpassbare Softwarelösungen für die Digitalisierung ihrer Terminprozesse benötigen."
    },
    feature_list_title: {
      en_US: "Everything in Business, plus",
      de_CH: "Alles in Business, plus"
    },
    feature_list: {
      sales: [
        {
          en_US: "25 users",
          de_CH: "25 Benutzer"
        },
        {
          en_US: "10 stores",
          de_CH: "10 Filialen"
        },
        {
          en_US: "10 conference appointments",
          de_CH: "10 Konferenztermine"
        },
        {
          en_US: "30 group appointments",
          de_CH: "30 Gruppentermine"
        },
        {
          en_US: "Corporate design & customization",
          de_CH: "Corporate Design & Branding"
        },
        {
          en_US: "Personal account manager (prioritized support according to SLA)",
          de_CH: "Persönlicher Account-Manager (priorisierter Support nach SLA)"
        },
        {
          en_US: "Company-specific user roles Advanced security settings",
          de_CH: "Erweiterte Sicherheitseinstellungen"
        },
        {
          en_US: "Audit Logs",
          de_CH: "Audit Logs"
        },
        {
          en_US: "Custom roles and permissions",
          de_CH: "Benutzerdefinierte Rollen und Berechtigungen"
        },
        {
          en_US: "Single-Sign-On (SSO): Microsoft Azure AD, Active Directory / LDAP",
          de_CH: "Single-Sign-On (SSO): Microsoft Azure AD, Active Directory / LDAP"
        },
        {
          en_US: "CRM and ERP integrations",
          de_CH: "CRM- und ERP Integrationen"
        },
        {
          en_US: "Dynamic Availabilities (via Office365 or Microsoft Exchange Calendar)",
          de_CH: "Dynamische Verfügbarkeiten (via Office365 oder Microsoft Exchange Kalender)"
        }
      ]
    },
  }
]

export const MAX_LIMITE = 100000;
export const MIN_LIMITE = 1;
export const MIN_CONFERENCE_LIMITE = 0;

export const EXTRA_USER_COST = 5;
export const EXTRA_GROUP_APPOINTMENT_COST = 10;
export const EXTRA_STORE_COST = 10;
export const EXTRA_CONFERENCE_COST = 10;

export const SALES_TOKEN = '82e80dc8-9318-4be5-9be1-e8ef79859fca';
