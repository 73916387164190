export const MAX_LIMIT = 100000;
export const MIN_LIMIT = 1;
export const MIN_CONFERENCE_LIMIT = 0;

export const EXTRA_USER_COST = 5;
export const EXTRA_GROUP_APPOINTMENT_COST = 10;
export const EXTRA_STORE_COST = 10;
export const EXTRA_CONFERENCE_COST = 10;

export const SALES_TOKEN = '82e80dc8-9318-4be5-9be1-e8ef79859fca';
