import { Directive, ElementRef, HostListener, Input, inject, numberAttribute } from '@angular/core';

@Directive({
  selector: '[appMaxInputValue]',
  standalone: true
})
export class MaxInputValueDirective {

  @Input({ transform: numberAttribute }) appMaxInputValue: number;

  private elementRef = inject(ElementRef);

  @HostListener('keypress', ['$event']) keyPress(event: KeyboardEvent) {
    const inputValue = Number(`${this.elementRef.nativeElement.value}${event.key}`);
    if (inputValue > this.appMaxInputValue) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

}
