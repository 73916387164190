import { PricingDetails } from "@db-models/pricing-db.model";

export const WEBSITE_PRICING_JSON: PricingDetails[] = [
  {
    chf: {
      yearly: {
        original_price: 74.90,
        user_base_price: 25,
      },
      monthly: {
        user_base_price: 30,
        original_price: 89.90,
      }
    },
    eur: {
      yearly: {
        original_price: 74.90,
        user_base_price: 25,
      },
      monthly: {
        user_base_price: 30,
        original_price: 89.90,
      }
    },
    description: "Calenso professional",
    id: 102,
    included_bookings: 0,
    name: "professional",
    min_workers: 3,
    min_store: 3,
    min_group_appointment: 10,
    min_conference: 0,
    subscription_title: 'Professional',
    subscription_subtitle: {
      en_US: "Ideal for businesses seeking personalized branding and dependable data protection.",
      de_CH: "Ideal für Unternehmen, die personalisierte Markenbildung und zuverlässigen Datenschutz suchen."
    },
    feature_list_title: {
      en_US: "Everything in Basic, plus",
      de_CH: "Alles in Basic, plus"
    },
    feature_list: {
      website: [
        {
          en_US: "Incl. 3 Users",
          de_CH: "Inkl. 3 Benutzer"
        },
        {
          en_US: "3 Branches",
          de_CH: "3 Filialen"
        },
        {
          en_US: "Unlimited Individual Appointments",
          de_CH: "Unbegrenzte Einzeltermine"
        },
        {
          en_US: "Group Bookings",
          de_CH: "Gruppenbuchungen"
        },
        {
          en_US: "Custom Logo",
          de_CH: "Benutzerdefiniertes Logo"
        },
        {
          en_US: "Google Calendar, iCloud, Office 365, Exchange Synchronization",
          de_CH: "Google Kalender, iCloud, Office 365, Exchange Synchronisation"
        },
        {
          en_US: "Video Consultation via Zoom, GoToMeeting & Webex",
          de_CH: "Video Beratung via Zoom, GoToMeeting & Webex"
        },
        {
          en_US: "Branches & Team Structure",
          de_CH: "Filialen- & Team-Struktur"
        },
        {
          en_US: "Analytics and Statistics",
          de_CH: "Analysen und Statistiken"
        },
        {
          en_US: "Standard Support via Email (72 hrs)",
          de_CH: "Standard Support via E-Mail (72 Std.)"
        }
      ]

    },
  },
  {
    chf: {
      yearly: {
        original_price: 199.90,
        user_base_price: 38
      },
      monthly: {
        original_price: 249.90,
        user_base_price: 50
      }
    },
    eur: {
      yearly: {
        original_price: 199.90,
        user_base_price: 38
      },
      monthly: {
        original_price: 249.90,
        user_base_price: 50
      }
    },
    description: "Calenso Business",
    id: 104,
    included_bookings: 0,
    name: "business",
    min_workers: 5,
    min_store: 5,
    min_group_appointment: 15,
    min_conference: 5,
    price_on_request: true,
    subscription_title: 'Business',
    subscription_subtitle: {
      en_US: "Tailored for companies aiming to digitize their appointment processes with a focus on reliable data protection and unique branding.",
      de_CH: "Zugeschnitten für Unternehmen, die ihre Terminprozesse digitalisieren möchten, mit Schwerpunkt auf zuverlässigem Datenschutz und einzigartigem Branding."
    },
    feature_list_title: {
      en_US: "Everything in Professional, plus",
      de_CH: "Alles in Professional, plus"
    },
    feature_list: {
      website: [
        {
          en_US: "Incl. 5 Users",
          de_CH: "Inkl. 5 Benutzer"
        },
        {
          en_US: "5 Branches",
          de_CH: "5 Filialen"
        },
        {
          en_US: "Conference Appointments (multiple hosts simultaneously)",
          de_CH: "Konferenztermine (mehrere Gastgeber gleichzeitig)"
        },
        {
          en_US: "Customizable Colors, Logo, and Branding",
          de_CH: "Anpassbare Farben, Logo und Branding"
        },
        {
          en_US: "Qualification Questions (Routing)",
          de_CH: "Qualifizierungsfragen (Routing)"
        },
        {
          en_US: "Smart Actions (Workflows)",
          de_CH: "Smart-Actions (Workflows)"
        },
        {
          en_US: "Encrypted Customer and Appointment Data",
          de_CH: "Verschlüsselte Kunden- und Termindaten"
        },
        {
          en_US: "Prioritized Support via Email with Callback Service (within 24 hrs)",
          de_CH: "Priorisierter Support via E-Mail mit Rückrufservice (innerhalb 24 Std.)"
        },
        {
          en_US: "Mail Dispatch from Own SMTP Mail Server",
          de_CH: "Mailversand von eigenem SMTP-Mailserver"
        }
      ]
    }
  },
  {
    chf: {
      yearly: {
        original_price: 0,
      },
      monthly: {
        original_price: 0,
      }
    },
    eur: {
      yearly: {
        original_price: 0,
      },
      monthly: {
        original_price: 0,
      }
    },
    description: "Calenso Enterprise",
    id: 105,
    included_bookings: 0,
    name: "enterprise",
    min_workers: 25,
    min_store: 10,
    min_group_appointment: 30,
    min_conference: 10,
    price_on_request: true,
    subscription_title: 'Enterprise',
    subscription_subtitle: {
      en_US: "Suited for companies requiring secure, scalable, and customizable software solutions for digitizing their appointment processes.",
      de_CH: "Geeignet für Unternehmen, die sichere, skalierbare und anpassbare Softwarelösungen für die Digitalisierung ihrer Terminprozesse benötigen."
    },
    feature_list_title: {
      en_US: "Everything in Business, plus",
      de_CH: "Alles in Business, plus"
    },
    feature_list: {
      website: [
        {
          en_US: "From 25 Users",
          de_CH: "Ab 25 Benutzer"
        },
        {
          en_US: "Corporate Design & Branding",
          de_CH: "Corporate Design & Branding"
        },
        {
          en_US: "Custom Booking Process",
          de_CH: "Individueller Buchungsprozess"
        },
        {
          en_US: "CRM & ERP Integrations (Salesforce, Dynamics, SAP, etc.)",
          de_CH: "CRM & ERP Integrationen (Salesforce, Dynamics, SAP etc.)"
        },
        {
          en_US: "Advanced Security Settings",
          de_CH: "Erweiterte Sicherheitseinstellungen"
        },
        {
          en_US: "Compliance with Audit Protocols",
          de_CH: "Compliance mit Audit-Protokollen"
        },
        {
          en_US: "Custom Roles and Permissions",
          de_CH: "Benutzerdefinierte Rollen und Berechtigungen"
        },
        {
          en_US: "Single Sign-On (SSO)",
          de_CH: "Single-Sign-on (SSO)"
        },
        {
          en_US: "Personalized Account Manager (Prioritized Support according to SLA)",
          de_CH: "Personalisierter Account Manager (Priorisierter Support gemäss SLA)"
        },
        {
          en_US: "Dynamic Availabilities (via Office365 or Microsoft Exchange Calendar)",
          de_CH: "Dynamische Verfügbarkeiten (via Office365 oder Microsoft Exchange Kalender)"
        }
      ]
    },
  }
]

export const MAX_LIMITE = 100000;
export const MIN_LIMITE = 1;
export const MIN_CONFERENCE_LIMITE = 0;

export const EXTRA_USER_COST = 5;
export const EXTRA_GROUP_APPOINTMENT_COST = 10;
export const EXTRA_STORE_COST = 10;
export const EXTRA_CONFERENCE_COST = 10;

export const SALES_TOKEN = '82e80dc8-9318-4be5-9be1-e8ef79859fca';

