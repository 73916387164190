import { registerLocaleData } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, inject, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CalensoPricingTableComponent } from '@app/calenso-pricing-table.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import * as Sentry from "@sentry/angular-ivy";
import { Observable, of } from 'rxjs';

import localeDe from '@angular/common/locales/de';
import localeDeCH from '@angular/common/locales/de-CH';

import { de_CH } from '@assets/i18n/de_CH';
import { en_US } from '@assets/i18n/en_US';

// required for currency and date pipe
registerLocaleData(localeDe, 'de');
registerLocaleData(localeDeCH, 'ch');

export class CustomTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    switch (lang) {
      case 'de_CH': return of(de_CH);
      case 'en_US': return of(en_US);
      default: return of(de_CH);
    }
  }
}

@NgModule({
  imports: [TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useClass: CustomTranslateLoader
    }
  }),
    BrowserAnimationsModule,
    RouterModule,
    CalensoPricingTableComponent], providers: [
      { provide: LOCALE_ID, useValue: 'de-ch' },
      { provide: ErrorHandler, useValue: Sentry.createErrorHandler({ showDialog: false, }) },
      { provide: APP_INITIALIZER, useFactory: () => () => { }, deps: [Sentry.TraceService], multi: true, },
      provideHttpClient(withInterceptorsFromDi())
    ]
})
export class AppModule {

  private injector = inject(Injector);

  ngDoBootstrap() {
    const el = createCustomElement(CalensoPricingTableComponent, { injector: this.injector });
    customElements.define('calenso-pricing-table', el);
  }
}
